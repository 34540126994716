<template>
  <section>
    <b-card class="box-shadow">
      <b-row>
        <!-- Mentor - Application -->
        <b-col sm="6">
          <aom-skeleton-loader v-if="loading" />
          <b-row v-else>
            <b-col
              sm="8"
              class="mb-1"
            >
              <h5 class="mt-2">
                Mentor - Application
              </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group label="Open Date">
                <div
                  v-if="mentorOpenDate"
                  class="d-flex align-items-center bg-light rounded p-1"
                >
                  <feather-icon
                    class="mr-1"
                    icon="CalendarIcon"
                    size="20"
                  />
                  {{ localeDateStringFromIsoDateTimeWithoutTimezone(mentorOpenDate) }}
                </div>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Close Date">
                <div
                  v-if="mentorCloseDate"
                  class="d-flex align-items-center bg-light rounded p-1"
                >
                  <feather-icon
                    class="mr-1"
                    icon="CalendarIcon"
                    size="20"
                  />
                  {{ localeDateStringFromIsoDateTimeWithoutTimezone(mentorCloseDate) }}
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-card
            v-for="(question, index) in mentorQuestions"
            :key="question.id"
            no-body
            class="mb-1"
          >
            <b-card-header
              header-tag="header"
              class="p-1"
              role="tab"
              :style="{ 'background-color': question.background }"
            >
              <b-col sm="10">
                <span>{{ index+1 }}. {{ question.translations[0].question_text }}</span>
              </b-col>

              <b-col
                sm="2"
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <span
                  v-if="question.is_visible"
                  class="font-small-1 mx-1"
                >
                  VISIBLE
                </span>
                <span v-if="question.type_id === questionTypes.MATCHING && question.question_matched_pair && question.question_matched_pair.weight">
                  <matched-question-weight :question="question" />
                </span>
              </b-col>
            </b-card-header>

            <b-card-body>
              <div v-if="question.type_id === questionTypes.TEXT">
                <label class="mt-1">Info text</label>
                <b-form-group label-for="prefilled-answer">
                  <b-form-textarea
                    :value="question.translations[0].prefilled_answer"
                    disabled
                    placeholder="Prefilled answer"
                    maxlength="255"
                    rows="3"
                  />
                </b-form-group>
              </div>
              <div v-if="question.type_id === questionTypes.CHOICE">
                <div
                  v-for="(choice, i) in question.choices"
                  :key="i"
                >
                  <b-row>
                    <b-col md="12">
                      <b-row>
                        <b-col md="1">
                          <input
                            type="checkbox"
                            disabled
                            name="question-options"
                            class="mt-1"
                          >
                        </b-col>
                        <b-col md="11">
                          <b-form-input
                            :value="
                              question.choices[i].translations[0].choice_text
                            "
                            disabled
                            class="mt-1"
                            type="text"
                            :placeholder="`Option ${i + 1}`"
                          />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div v-if="question.type_id === questionTypes.MATCHING">
                <div
                  v-for="(choice, i) in question.choices"
                  :key="i"
                >
                  <b-row>
                    <b-col sm="12">
                      <h5>Mentor Answers</h5>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-row>
                        <b-col md="1">
                          <input
                            type="checkbox"
                            disabled
                            name="question-options"
                            class="mt-2"
                          >
                        </b-col>
                        <b-col md="11">
                          <b-form-input
                            :value="question.choices[i].translations[0].choice_text"
                            disabled
                            class="mt-1"
                            type="text"
                            :placeholder="`Option ${i + 1}`"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="12">
                          <hr>
                          <h5 class="text-muted">
                            Compatible Mentee Answers
                          </h5>
                          <b-row
                            v-for="menteeChoice in findMatchingMenteeChoices(question)" 
                            :key="menteeChoice.id" 
                            class="mt-1 flex-nowrap px-2"
                          >
                            <b-col md="1">
                              <input
                                type="checkbox"
                                disabled
                                name="question-options"
                                :checked="isMatchingOption(question.choices[i].id, menteeChoice)"
                                class="mt-2"
                              >
                            </b-col>
                            <b-col md="11">
                              <b-form-input
                                :value="menteeChoice.translations[0].choice_text"
                                disabled
                                class="mt-1"
                                type="text"
                                :placeholder="`Option ${i + 1}`"
                              />
                            </b-col>
                          </b-row>
                          <hr>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div v-if="question.type_id === questionTypes.SCALE">
                <b-row
                  v-for="indexRow in (question.statements.length + 1)" 
                  :key="indexRow" 
                  class="mt-1 flex-nowrap px-2"
                >
                  <div 
                    v-for="indexCol in question.choices.length + 1"
                    :key="indexCol" 
                    class="pl-50 pr-50 px-2"
                    :style="getColWidth(question.statements, question.choices, indexCol - 2)"
                  >
                    <div
                      v-if="indexRow === 1 && indexCol > 1 && indexCol < (question.choices.length + 2) "
                    >
                      <b-input-group 
                        :label-for="'option' + (indexCol-1) "
                      >
                        <span
                          v-if="question.choices[indexCol - 2].translations[0]"
                          disabled
                          :value="question.choices[indexCol - 2].translations[0].choice_text"
                          type="text"
                          class="min-statement-width"
                          :placeholder="'Option ' + (indexCol-1) "
                          style="overflow:hidden"
                          size="sm"
                          no-resize
                        >{{ question.choices[indexCol - 2].translations[0].choice_text }} </span>
                      </b-input-group>
                    </div>
                    <div
                      v-else-if="indexRow > 1 && indexCol === 1"
                    >
                      <b-input-group 
                        :label-for="'statement' + (indexRow-1) "
                      >
                        <span
                          v-if="question.statements[indexRow - 2].translations[0]"
                          :value="question.statements[indexRow - 2].translations[0].question_text"
                          disabled
                          class="min-statement-width" 
                          type="text"
                          :placeholder="`Statement ${indexRow - 1}`"
                          style="overflow:hidden"
                          size="sm"
                          no-resize
                        >
                          {{ question.statements[indexRow - 2].translations[0].question_text }}
                        </span>
                      </b-input-group>
                    </div>
                    <div v-else-if="indexRow > 1 && indexCol > 1 && indexCol < (question.choices.length + 2)">
                      <input
                        type="radio"
                        disabled
                        name="option-radio" 
                      >
                    </div>
                  </div>
                </b-row>
              </div>
            </b-card-body>
          </b-card>
        </b-col>

        <!-- Mentee - Application -->
        <b-col sm="6">
          <aom-skeleton-loader v-if="loading" />
          <b-row v-else>
            <b-col
              sm="8"
              class="mb-1"
            >
              <h5 class="mt-2">
                Mentee - Application
              </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group label="Open Date">
                <div class="d-flex align-items-center bg-light rounded p-1">
                  <feather-icon
                    class="mr-1"
                    icon="CalendarIcon"
                    size="20"
                  />
                  {{ localeDateStringFromIsoDateTimeWithoutTimezone(menteeOpenDate) }}
                </div>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Close Date">
                <div class="d-flex align-items-center bg-light rounded p-1">
                  <feather-icon
                    class="mr-1"
                    icon="CalendarIcon"
                    size="20"
                  />
                  {{ localeDateStringFromIsoDateTimeWithoutTimezone(menteeCloseDate) }}
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-card
            v-for="(question, index) in menteeQuestions"
            :key="question.id"
            class="mb-1"
          >
            <b-card-header
              header-tag="header"
              class="p-1"
              role="tab"
              :style="{ 'background-color': question.background }"
            >
              <b-col sm="10">
                <span>{{ index + 1 }}. {{ question.translations[0].question_text }}</span>
              </b-col>

              <b-col
                sm="2"
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <span
                  v-if="question.is_visible"
                  class="font-small-1 mx-1"
                >
                  VISIBLE
                </span>
                <span v-if="question.type_id === questionTypes.MATCHING && question.question_matched_pair && question.question_matched_pair.weight">
                  <matched-question-weight :question="question" />
                </span>
              </b-col>
            </b-card-header>
            <b-card-body>
              <div v-if="question.type_id === questionTypes.TEXT">
                <label class="mt-1">Info text</label>
                <b-form-group label-for="prefilled-answer">
                  <b-form-textarea
                    :value="question.translations[0].prefilled_answer"
                    disabled
                    placeholder="Prefilled answer"
                    maxlength="255"
                    rows="3"
                  />
                </b-form-group>
              </div>
              <div v-if="question.type_id === questionTypes.CHOICE">
                <div
                  v-for="(choice, i) in question.choices"
                  :key="i"
                >
                  <b-row>
                    <b-col md="12">
                      <b-row>
                        <b-col md="1">
                          <input
                            type="checkbox"
                            disabled
                            name="question-options"
                            class="mt-1"
                          >
                        </b-col>
                        <b-col md="11">
                          <b-form-input
                            :value="
                              question.choices[i].translations[0]
                                .choice_text
                            "
                            disabled
                            class="mt-1"
                            type="text"
                            :placeholder="`Option ${i + 1}`"
                          />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div v-if="question.type_id === questionTypes.MATCHING">
                <div
                  v-for="(choice, i) in question.choices"
                  :key="i"
                >
                  <b-row>
                    <b-col sm="12">
                      <h5>Mentee Answers</h5>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-row>
                        <b-col md="1">
                          <input
                            type="checkbox"
                            disabled
                            name="question-options"
                            class="mt-2"
                          >
                        </b-col>
                        <b-col md="11">
                          <b-form-input
                            :value="question.choices[i].translations[0].choice_text"
                            disabled
                            class="mt-1"
                            type="text"
                            :placeholder="`Option ${i + 1}`"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="12">
                          <hr>
                          <h5 class="text-muted">
                            Compatible Mentor Answers
                          </h5>
                          <b-row
                            v-for="mentorChoice in findMatchingMentorChoices(question)" 
                            :key="mentorChoice.id" 
                            class="mt-1 flex-nowrap"
                          >
                            <b-col md="1">
                              <input
                                type="checkbox"
                                disabled
                                name="question-options"
                                :checked="isMatchingOptionMentee(question.choices[i].id, mentorChoice)"
                                class="mt-2"
                              >
                            </b-col>
                            <b-col md="11">
                              <b-form-input
                                :value="mentorChoice.translations[0].choice_text"
                                disabled
                                class="mt-1"
                                type="text"
                                :placeholder="`Option ${i + 1}`"
                              />
                            </b-col>
                          </b-row>
                          <hr>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div v-if="question.type_id === questionTypes.SCALE">
                <b-row
                  v-for="indexRow in question.statements.length + 1"
                  :key="indexRow"
                  class="mt-1 flex-nowrap"
                >
                  <div
                    v-for="indexCol in question.choices.length + 1"
                    :key="indexCol"
                    class="pl-50 pr-50 px-2"
                    :style="getColWidth(question.statements, question.choices, indexCol - 2)"
                  >
                    <div
                      v-if="
                        indexRow === 1 &&
                          indexCol > 1 &&
                          indexCol < question.choices.length + 2
                      "
                    >
                      <b-input-group :label-for="'option' + (indexCol - 1)">
                        <span
                          v-if="
                            question.choices[indexCol - 2].translations[0]
                          "
                          disabled
                          :value="
                            question.choices[indexCol - 2].translations[0]
                              .choice_text
                          "
                          type="text"
                          class="min-statement-width"
                          :placeholder="'Option ' + (indexCol - 1)"
                          style="overflow: hidden"
                          size="sm"
                          no-resize
                        >{{ question.choices[indexCol - 2].translations[0].choice_text }}
                        </span>
                      </b-input-group>
                    </div>
                    <div v-else-if="indexRow > 1 && indexCol === 1">
                      <b-input-group
                        :label-for="'statement' + (indexRow - 1)"
                      >
                        <span
                          v-if="
                            question.statements[indexRow - 2]
                              .translations[0]
                          "
                          disabled
                          class="min-statement-width"
                          type="text"
                          :placeholder="`Statement ${indexRow - 1}`"
                          style="overflow: hidden"
                          size="sm"
                          no-resize
                        >{{ question.statements[indexRow - 2].translations[0].question_text }}
                        </span>
                      </b-input-group>
                    </div>
                    <div
                      v-else-if="
                        indexRow > 1 &&
                          indexCol > 1 &&
                          indexCol < question.choices.length + 2
                      "
                    >
                      <input
                        type="radio"
                        disabled
                        name="option-radio"
                      >
                    </div>
                  </div>
                </b-row>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>
    
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BCardBody,
  BCardHeader, 
} from "bootstrap-vue";

import { questionTypes, userRoles, actions } from "@/models";
import { getValidationState } from "@/libs/utils";
import { programsService } from "@/services";
import { makeErrorToast } from "@/libs/utils";
import MatchedQuestionWeight from "@/views/apps/champion/matches/MatchedQuestionWeight.vue";
import { localeDateStringFromIsoDateTimeWithoutTimezone } from "@/libs/utils";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";

export default {
  name: 'PrintApplication',
  components: {
    BCard,
    BRow,
    BCol,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BCardBody,
    BCardHeader,
    MatchedQuestionWeight,
    AomSkeletonLoader
  },
  data() {
    return {
      loading: false,
      mentorQuestions: {},
      menteeQuestions: {},
      menteeApplication: {},
      mentorApplication: {}
    };
  },
  computed: {
    hasApplication () {
      return this.program?.application_set;
    },
    isMentorImport() {
      return this.role === userRoles.MENTOR;
    },
    mentorOpenDate() {
      if (this.mentorApplication && this.mentorApplication.scheduled_actions) {
        return this.menteeApplication.scheduled_actions.filter(sa => sa.action_id === actions.SHOW)[0]?.absolute_date;
      }
      return null;
    },
    mentorCloseDate() {
      if (this.mentorApplication && this.mentorApplication.scheduled_actions) {
        return this.menteeApplication.scheduled_actions.filter(sa => sa.action_id === actions.HIDE)[0]?.absolute_date;
      }
      return null;
    },
    menteeOpenDate() {
      if (this.menteeApplication && this.menteeApplication.scheduled_actions) {
        return this.menteeApplication.scheduled_actions.filter(sa => sa.action_id === actions.SHOW)[0]?.absolute_date;
      }
      return null;
    },
    menteeCloseDate() {
      if (this.menteeApplication && this.menteeApplication.scheduled_actions) {
        return this.menteeApplication.scheduled_actions.filter(sa => sa.action_id === actions.HIDE)[0]?.absolute_date;
      }
      return null;
    }
  },
  async created() {
    await this.fetchApplicationSet();
  },
  methods: {
    async fetchApplicationSet() {
      try {
        this.loading = true;
        const response = await programsService.getApplicationSetByProgramId(
          this.$route.params.id,
          this.$route.params.applicationId
        );
        const { data } = response;
        const { applications } = data;
        this.menteeApplication = applications.find(a => a.roles[0].id === userRoles.MENTEE);
        this.mentorApplication = applications.find(a => a.roles[0].id === userRoles.MENTOR);
        this.menteeQuestions = this.menteeApplication.questions;
        this.mentorQuestions = this.mentorApplication.questions;
      } catch (e) {
        console.log(e);
        const { data } = e.response;
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.loading = false;
      }
    },
    findMatchingMenteeChoices(question) {
      return this.menteeQuestions.find(q => q.question_matched_pair_id === question.question_matched_pair_id)?.choices || [];
    },
    findMatchingMentorChoices(question) {
      return this.mentorQuestions.find(q => q.question_matched_pair_id === question.question_matched_pair_id)?.choices || [];
    },
    isMatchingOption(mentorQuestionChoiceId, menteeChoice) {
      return menteeChoice.matched_choices.find(mc => mc.id === mentorQuestionChoiceId)?.id !== undefined;
    },
    isMatchingOptionMentee(menteeQuestionChoiceId, mentorChoice) {
      return mentorChoice.matched_choices.find(mc => mc.id === menteeQuestionChoiceId)?.id !== undefined;
    },
    /*
    * Layout of question
    * column1            column2   column3   column4   ...
    * longestStatement   choice1   choice2   choice3   ...
    */
    getColWidth(statements, choices, indexChoice) {
      if (indexChoice > choices.length) {
        return '';
      }
      // Calculate string length of the longest statement
      const longestStatementLength = statements.reduce((maxLength, statement) => {
        const statementText = statement.translations && statement.translations.length
          ? statement.translations[0].question_text
          : "";
        return Math.max(maxLength, statementText.length);
      }, 0);
      // Calculate total of string length of choices
      const totalChoicesLength = choices.reduce((acc, choice) => {
        const text = choice.translations && choice.translations.length ? choice.translations[0].choice_text : "";
        return acc + text.length;
      }, 0);

      let currentChoiceText = "";
      if (indexChoice > -1) {
        currentChoiceText = choices[indexChoice].translations && choices[indexChoice].translations.length ? choices[indexChoice].translations[0].choice_text : "";
      }
      const currentColLength = indexChoice === -1 ? longestStatementLength : currentChoiceText.length;
      const itemWidth = (currentColLength * 100) / (longestStatementLength + totalChoicesLength);
      return `width: ${Math.max(itemWidth, 5)}%`;// Set min width is 5% if column width is too small
    },
  },
  setup() {
    return {
      getValidationState,
      questionTypes,
      localeDateStringFromIsoDateTimeWithoutTimezone
    };
  },
};
</script>
<style lang="scss" scoped>
.card {
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
.col-sm-50 {
    width:9.33%
}
</style>